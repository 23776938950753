<template>
    <section class="section">
        <ValidationObserver v-slot="{ handleSubmit }">
            <v-form class="mt-15" @submit.prevent="handleSubmit(onSubmit)">
                <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" max-height="90vh">
                    <v-container>
                        <v-tabs center-active>
                            <v-tab>{{ $t('CalendarProfile') }}</v-tab>
                            <v-tab :disabled="isLoading">{{ $t('Trips') }}</v-tab>
                            <v-tab-item>
                                <v-row class="mt-10">
                                    <v-col cols="12" md="12">
                                        <v-row>
                                            <v-col cols="12" md="4">
                                                <ValidationProvider
                                                    :name="$t('StartDate')"
                                                    rules="required"
                                                    v-slot="{ errors }">
                                                    <v-menu
                                                        ref="startDateMenu"
                                                        v-model="startDateMenu"
                                                        :close-on-content-click="false"
                                                        :return-value.sync="form.start_date"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                v-model="form.start_date"
                                                                :error="errors[0] ? true : false"
                                                                :disabled="isDisabled"
                                                                :label="$t('StartDate')"
                                                                readonly
                                                                filled
                                                                hide-details="auto"
                                                                shaped
                                                                v-bind="attrs"
                                                                v-on="on"></v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="form.start_date" no-title scrollable>
                                                            <v-spacer></v-spacer>
                                                            <v-btn text color="primary" @click="startDateMenu = false">
                                                                Cancel
                                                            </v-btn>
                                                            <v-btn
                                                                text
                                                                color="primary"
                                                                @click="$refs.startDateMenu.save(form.start_date)">
                                                                OK
                                                            </v-btn>
                                                        </v-date-picker>
                                                    </v-menu>

                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" md="4">
                                                <ValidationProvider
                                                    :name="$t('EndDate')"
                                                    rules="required"
                                                    v-slot="{ errors }">
                                                    <v-menu
                                                        ref="endDateMenu"
                                                        v-model="endDateMenu"
                                                        :close-on-content-click="false"
                                                        :return-value.sync="form.end_date"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                v-model="form.end_date"
                                                                :error="errors[0] ? true : false"
                                                                :disabled="isDisabled"
                                                                :label="$t('EndDate')"
                                                                readonly
                                                                filled
                                                                hide-details="auto"
                                                                shaped
                                                                v-bind="attrs"
                                                                v-on="on"></v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="form.end_date"
                                                            :min="form.start_date"
                                                            no-title
                                                            scrollable>
                                                            <v-spacer></v-spacer>
                                                            <v-btn text color="primary" @click="endDateMenu = false">
                                                                Cancel
                                                            </v-btn>
                                                            <v-btn
                                                                text
                                                                color="primary"
                                                                @click="$refs.endDateMenu.save(form.end_date)">
                                                                OK
                                                            </v-btn>
                                                        </v-date-picker>
                                                    </v-menu>

                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>
                                            <!--  <v-col cols="12" md="4">
                                                <ValidationProvider
                                                    :name="$tc('DaysAlwaysAvailable')"
                                                    v-slot="{ errors }">
                                                    <v-select
                                                        :error="errors[0] ? true : false"
                                                        :disabled="isDisabled"
                                                        :items="weekDays"
                                                        filled
                                                        shaped
                                                        multiple
                                                        item-value="value"
                                                        item-text="name"
                                                        hide-details
                                                        v-model="selectedDays"
                                                        :label="$tc('DaysAlwaysAvailable')">
                                                        <template v-slot:prepend-item>
                                                            <v-list-item ripple @mousedown.prevent @click="toggle">
                                                                <v-list-item-action>
                                                                    <v-icon
                                                                        :color="
                                                                            selectedDays.length > 0
                                                                                ? 'indigo darken-4'
                                                                                : ''
                                                                        ">
                                                                        {{ icon }}
                                                                    </v-icon>
                                                                </v-list-item-action>
                                                                <v-list-item-content>
                                                                    <v-list-item-title> Select All </v-list-item-title>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-divider class="mt-2"></v-divider>
                                                        </template>
                                                    </v-select>
                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col> -->
                                        </v-row>

                                        <v-row>
                                            <v-col cols="12" md="4" class="d-flex align-self-center"
                                                ><span>{{ $t('DaysAlwaysAvailable') }}</span></v-col
                                            >
                                            <v-col cols="12" md="4">
                                                <v-checkbox
                                                    :label="$t('SelectAll')"
                                                    :value="allDaysSelected"
                                                    @change="selectAll"
                                                    :disabled="isDisabled"></v-checkbox
                                            ></v-col>
                                            <v-col
                                                cols="12"
                                                md="4"
                                                class="d-flex align-self-center"
                                                v-if="selectedDays.length > 0">
                                                <v-btn
                                                    @click="deselectAll"
                                                    expanded
                                                    class="text-capitalize"
                                                    text
                                                    color="error"
                                                    style="font-size: 16px; padding: 0"
                                                    :disabled="isDisabled">
                                                    {{ $t('DeselectAll') }}

                                                    <v-icon>
                                                        {{ mdiTrashCanOutline }}
                                                    </v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" md="4" v-for="day in weekDays" :key="day.value">
                                                <v-checkbox
                                                    :label="day.name"
                                                    :value="day.value"
                                                    v-model="selectedDays"
                                                    :disabled="isDisabled"></v-checkbox>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item>
                                <div class="d-flex mt-8">
                                    <v-text-field
                                        expanded
                                        v-model="searchTripsQuery"
                                        @change="searchTrips()"
                                        :placeholder="$t('Search')"
                                        outlined
                                        hide-details
                                        dense
                                        :prepend-inner-icon="mdiMagnify"
                                        @keydown.enter.prevent="searchTrips"
                                        class="user-search me-3 mb-4">
                                    </v-text-field>
                                </div>
                                <ValidationProvider
                                    :name="$t('Trips')"
                                    rules="required"
                                    v-slot="{ errors }"
                                    ref="trips">
                                    <v-data-table
                                        show-select
                                        calculate-widths
                                        :items-per-page="5"
                                        :footer-props="{
                                            'items-per-page-options': [5, 10, 15, 20],
                                            'items-per-page-text': $t('RowsPerPage'),
                                        }"
                                        :header-props="{ 'sort-by-text': $t('SortBy') }"
                                        :headers="tripsTableColumns"
                                        :items="form.trips.length > 0 ? orderBySelectedTrip : tripListTable"
                                        :server-items-length="totalTripListTable"
                                        v-model="form.trips"
                                        name="trips"
                                        required
                                        :options.sync="tripOptions"
                                        @update:page="paginationHandle"
                                        height="500"
                                        fixed-header>
                                    </v-data-table>
                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </v-tab-item>
                        </v-tabs>
                    </v-container>
                </v-sheet>
                <v-app-bar height="80" absolute color="white" elevate-on-scroll scroll-target="#scrolling-techniques-7">
                    <v-btn @click="goBack" class="square-button mx-3" color="grey-light">
                        <v-icon large color="grey">
                            {{ mdiChevronLeft }}
                        </v-icon>
                    </v-btn>
                    <v-toolbar-title v-if="!addCalendar">
                        <p class="text-h5 font-weight-semibold black--text mt-1 d-none d-md-flex d-lg-flex d-xl-flex">
                            {{ $t('CalendarProfile') }}
                        </p>
                        <p class="text-h6 font-weight-semibold black--text mt-1 d-flex d-xs-flex d-sm-flex d-md-none">
                            {{ $t('CalendarProfile') }}
                        </p>
                    </v-toolbar-title>
                    <v-toolbar-title v-else>
                        <p class="text-h5 font-weight-semibold black--text mt-1 d-none d-md-flex d-lg-flex d-xl-flex">
                            {{ $t('AddCalendar') }}
                        </p>
                        <p class="text-h6 font-weight-semibold black--text mt-1 d-flex d-xs-flex d-sm-flex d-md-none">
                            {{ $t('AddCalendar') }}
                        </p>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <div v-if="!addCalendar">
                        <v-btn type="submit" v-show="!isDisabled" :loading="isLoading" class="mx-3" color="primary">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="white">
                                {{ mdiCheck }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="ehite">
                                {{ mdiCheck }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Save') }}</span>
                        </v-btn>
                        <v-btn v-show="!isDisabled" @click="isDisabled = true" outlined class="mx-3" color="error">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="error">
                                {{ mdiClose }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="error">
                                {{ mdiClose }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Cancel') }}</span>
                        </v-btn>

                        <v-dialog transition="dialog-top-transition" max-width="600">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-show="isDisabled" class="mx-3" color="grey-light" v-bind="attrs" v-on="on">
                                    <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="grey">
                                        {{ mdiTrashCanOutline }}
                                    </v-icon>
                                    <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="grey">
                                        {{ mdiTrashCanOutline }}
                                    </v-icon>
                                    <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Delete') }}</span>
                                </v-btn>
                            </template>
                            <template v-slot:default="dialog">
                                <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/delete.png"></v-img>
                                <v-card>
                                    <v-card-text class="text-center">
                                        <p class="title font-weight-semibold black--text mt-12">
                                            {{ $t('Alerts.Sure') }}
                                        </p>
                                    </v-card-text>
                                    <v-card-text class="text-center">
                                        <p class="body-2">
                                            {{ $t('Alerts.SureDeleteCalendar') }}
                                            <strong class="black--text"></strong> ?<br />
                                            {{ $t('Alerts.Irreversible') }}
                                        </p>
                                    </v-card-text>

                                    <v-card-actions class="justify-end mt-4">
                                        <v-btn
                                            @click="deleteCalendar"
                                            :loading="isLoading"
                                            color="error"
                                            class="px-5"
                                            >{{ $t('Buttons.Delete') }}</v-btn
                                        >
                                        <v-btn text @click="dialog.value = false" :loading="isLoading">{{
                                            $t('Buttons.Cancel')
                                        }}</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </template>
                        </v-dialog>
                    </div>
                    <v-btn v-else type="submit" :loading="isLoading" class="mx-3" color="primary">
                        <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="white">
                            {{ mdiCheck }}
                        </v-icon>
                        <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="white">
                            {{ mdiCheck }}
                        </v-icon>
                        <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Conclude') }}</span>
                    </v-btn>
                </v-app-bar>
            </v-form>
        </ValidationObserver>
        <v-snackbar :timeout="2000" top v-model="createSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.AddCalendarSuccess') }}</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="updateSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.UpdateCalendarSuccess') }}</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="deleteSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.DeleteCalendarSuccess') }}</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="isError" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/failed.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Error') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.ErrorMessage') }}</p>
        </v-snackbar>
    </section>
</template>

<script>
    import {
        mdiChevronLeft,
        mdiFileDocumentEditOutline,
        mdiInformationOutline,
        mdiPlus,
        mdiTrashCanOutline,
        mdiCheck,
        mdiClose,
        mdiChevronRight,
        mdiMagnify,
        mdiCheckboxBlankOutline,
        mdiCloseBox,
    } from '@mdi/js';
    import _ from 'lodash';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import useTripsList from '@/views/route/trips-list/useTripsList';

    const initialState = () => ({
        isLoading: false,
        form: {
            monday: 'O0',
            tuesday: 'O0',
            wednesday: 'O0',
            thursday: 'O0',
            friday: 'O0',
            saturday: 'O0',
            sunday: 'O0',
            calendar_dates: [],
            trips: [],
            start_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
            end_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
        },

        isLoadingTable: true,
    });

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
        },

        data() {
            return {
                ...initialState(),
                isDisabled: true,
                valid: false,

                addCalendar: false,

                isError: false,
                createSuccess: false,
                updateSuccess: false,
                deleteSuccess: false,
                startDateMenu: false,
                endDateMenu: false,
                searchTripsQuery: null,
                tripOptions: {
                    page: 1,
                },
                daysAvailability: [
                    { name: 'NotAlwaysAvailable', value: 'O0' },
                    { name: 'AlwaysAvailable', value: 'O1' },
                ],
                tripQuery: {},

                weekDays: [
                    { name: 'Monday', value: 'monday' },
                    { name: 'Tuesday', value: 'tuesday' },
                    { name: 'Wednesday', value: 'wednesday' },
                    { name: 'Thursday', value: 'thursday' },
                    { name: 'Friday', value: 'friday' },
                    { name: 'Saturday', value: 'saturday' },
                    { name: 'Sunday', value: 'sunday' },
                ],

                selectedDays: [],
            };
        },
        created() {
            this.tripQuery = this.$store.getters['app-routes/getTripsQuery'];
            this.fetchTripList();
            for (let index = 0; index < this.daysAvailability.length; index++) {
                this.daysAvailability[index].name = this.$t(this.daysAvailability[index].name);
            }

            for (let index = 0; index < this.weekDays.length; index++) {
                this.weekDays[index].name = this.$tc(this.weekDays[index].name);
            }
            if (this.$route.params.id != 'add') {
                this.addCalendar = false;
                this.isDisabled = true;
                this.getCalendar();
            } else {
                this.addCalendar = true;
                this.isDisabled = false;
            }
        },

        methods: {
            editInformation() {
                this.isDisabled = !this.isDisabled;
            },

            getCalendar() {
                this.isLoading = true;
                this.$store
                    .dispatch('app-routes/fetchCalendar', this.$route.params.id)
                    .then((response) => {
                        this.isLoading = false;

                        this.form = response.data.data;

                        if (this.form.monday === 'O1') {
                            this.selectedDays.push('monday');
                        }

                        if (this.form.tuesday === 'O1') {
                            this.selectedDays.push('tuesday');
                        }

                        if (this.form.wednesday === 'O1') {
                            this.selectedDays.push('wednesday');
                        }

                        if (this.form.thursday === 'O1') {
                            this.selectedDays.push('thursday');
                        }

                        if (this.form.friday === 'O1') {
                            this.selectedDays.push('friday');
                        }

                        if (this.form.saturday === 'O1') {
                            this.selectedDays.push('saturday');
                        }

                        if (this.form.sunday === 'O1') {
                            this.selectedDays.push('sunday');
                        }
                    })
                    .catch((error) => {
                        if (error.response.status === 404) {
                            this.form = {};
                        }
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },

            deleteCalendar() {
                this.isLoading = true;
                this.$store
                    .dispatch('app-routes/deleteCalendar', this.$route.params.id)
                    .then((response) => {
                        this.deleteSuccess = true;
                        setTimeout(() => {
                            this.$router.push({ name: 'RoutesList', params: { tab: 1, tripTab: 2, calendarTab: 0 } });
                        }, 1500);
                    })
                    .catch((error) => {
                        this.isError = true;
                    });
            },

            searchTrips() {
                this.isLoading = true;
                this.$store.commit('app-routes/setTripSearch', this.searchTripsQuery);
                this.tripQuery = this.$store.getters['app-routes/getTripsQuery'];
                this.tripQuery.pagination.page = 1;
                this.fetchTripList();
                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);
            },

            paginationHandle(e) {
                if (this.tripOptions.page > this.tripQuery.pagination.page) {
                    this.tripQuery.pagination.page = this.tripOptions.page;
                    this.$store.commit('app-routes/setRoutesQuery', this.tripQuery);
                    this.fetchTripList();
                }
            },

            selectAll() {
                this.deselectedValue = false;
                if (this.allDaysSelected) {
                    this.selectedDays = [];
                } else {
                    this.selectedDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
                }
            },

            deselectAll() {
                this.selectedDays = [];
            },
            goBack() {
                this.$router.push({ name: 'RoutesList', params: { tab: 1, tripTab: 2, calendarTab: 0 } });
            },
        },

        computed: {
            allDaysSelected() {
                return this.selectedDays.length === this.weekDays.length;
            },

            someDaysSelected() {
                return this.selectedDays.length > 0 && !this.allDaysSelected;
            },

            icon() {
                if (this.allDaysSelected) return 'mdi-close-box';
                if (this.someDaysSelected) return 'mdi-minus-box';
                return mdiCheckboxBlankOutline;
            },

            orderBySelectedTrip() {
                for (let index = 0; index < this.form.trips.length; index++) {
                    this.tripListTable.unshift(this.form.trips[index]);

                    //remove duplicates from array
                    this.tripListTable = this.tripListTable.filter(
                        (trip, index, self) => index === self.findIndex((i) => i.id === trip.id),
                    );
                }

                return this.tripListTable;
            },
        },

        setup() {
            const { tripsTableColumns, tripListTable, totalTripListTable, fetchTripList } = useTripsList();
            return {
                mdiInformationOutline,
                mdiFileDocumentEditOutline,
                mdiCheck,
                mdiClose,
                mdiTrashCanOutline,
                mdiPlus,
                mdiChevronLeft,
                mdiChevronRight,
                mdiMagnify,
                mdiCheckboxBlankOutline,
                mdiCloseBox,

                tripsTableColumns,
                tripListTable,
                totalTripListTable,
                fetchTripList,
            };
        },
    };
</script>

<style lang="scss" scoped>
    .v-application.theme--light .v-tabs:not(.v-tabs--vertical) {
        box-shadow: 0 0px 0px 0px rgb(94 86 105 / 42%) !important;
    }

    .route-color-picker {
        position: absolute;
        z-index: 10;
        display: none;
    }

    .route-text-color-picker {
        position: absolute;
        z-index: 10;
        display: none;
    }
</style>
